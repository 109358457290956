import * as React from "react"
import styled from "@emotion/styled"
import { theme } from "common/colorScheme"
import { ContentfulLink } from "common/types"
import Link from "components/Link"
import { css } from "@emotion/react"
import { useEffect, useMemo, useState } from "react"
import { getPath } from "components/button"
import Media from "components/Media"
import {ArrowRightNewIcon} from "components/icons"
import { IFeatured, IFeaturedTypes } from "../types"

export interface IFlyoutMenuProps {
	links: ContentfulLink[]
	active?: boolean
	featuredBlogPost?: IFeatured
	featuredType?: IFeaturedTypes
	showCategory?: boolean
	itemsPerColumn?: number;
  	totalColumns?: number;
	highlightedLinks?: ContentfulLink[]

}

interface IContainerProps {
	active?: boolean
}

interface IColumn {
	id: string
	items: ContentfulLink[]
}

interface IFeaturedImageContainerProps {
	visible?: boolean
}

const ItemTitle = styled.div`
	font-weight: 600;
	font-size: 1.7rem;
	line-height: 100%;
	margin-bottom: ${theme.spacing(1)};
	transition: color 0.05s ease-in-out;
	display: flex;
	color: ${theme.color.darkPurple};
`

const ItemDescription = styled.div`
	font-size: 1.4rem;
	line-height: 1.8rem;
`

const Item = styled.div`
	color: black;
	padding-bottom: ${theme.spacing(1)};
	&:hover {
		${ItemTitle} {
			color: ${theme.color.purple};
		}
	}
`
const ArrowIcon = styled.div<any>`
    height: 18px;
    width: 30px;
    min-height: 18px;
    min-width: 18px;
    background-image: url("${ArrowRightNewIcon}");
    background-repeat: no-repeat;
    background-position: center left;
	background-size: 70% 70%;
    cursor: pointer;
    border-radius: 8px;
    border-radius: 50%;
    z-index: 1;
  
`
const Column = styled.div`
	width: 100%;
	height: 100%;
	margin: ${theme.spacing(4, 0, 4, 0)};
	max-width: 380px;
	
	&:first-of-type {
		margin-left: ${theme.spacing(0)};
	}

	${Item}:not(:first-of-type) {
		margin-top: ${theme.spacing(2)};
	}
`

const Container = styled.div<IContainerProps>`
	position: fixed;
	padding: 40px 80px;
	width: 100%;
	display: flex;
	top: 70px;
	left: 0;
	background-color: #fff;
	opacity: ${({ active }) => (active ? 1 : 0)};
	transform: ${({ active }) => `translateY(${active ? "0" : "-10px"})`};
	transition: all 0.15s ease-in-out;
	pointer-events: ${({ active }) => (active ? "all" : "none")};
	box-shadow: 0px 30px 60px 0 rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	overflow: hidden;

	${Column}:not(:first-of-type) {
		margin-left: ${theme.spacing(3)};
	}
`

const linkStyles = css`
	text-decoration: none;
	color: unset;
`

const FeaturedPageContainer = styled.a`
	width: calc(180px + ${theme.spacing(8)});
	flex: 1;
	background-color: #f8f8f8;
	padding: ${theme.spacing(4)};
	color: #000;
	text-decoration: none;
`

const FeaturedPageImagesWrapper = styled.div`
	height: 180px;
	width: 180px;
	position: relative;
	margin-bottom: ${theme.spacing(3)};
`
const FeaturedPageImageContainer = styled.div<IFeaturedImageContainerProps>`
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	border-radius: 50%;
	background-color: #f1f1f1;
	opacity: ${({ visible }) => (visible ? 1 : 0)};
	transition: opacity 0.4s ease-in-out;

	.gatsby-image-wrapper {
		pointer-events: none;
	}
`

const FeaturedPageCategory = styled.p`
	font-weight: 800;
	font-size: 1.1rem;
	line-height: 100%;
	text-transform: uppercase;
	color: ${theme.color.purple};
	margin: 0;
	margin-bottom: ${theme.spacing(1)};
	letter-spacing: 1px;
`

const FeaturedTitle = styled.span`
	font-weight: 800;
	font-size: 1.6rem;
	line-height: 110%;
	margin: 0;
	margin-bottom: ${theme.spacing(1)};
`

const FeaturedDescription = styled.p`
	font-size: 1.2rem;
	line-height: 1.8rem;
	margin: 0;
`

const HighlightedColumn = styled.div`
  background-color: #f8f8ff;
  padding: ${theme.spacing(4)};
  border-radius: 8px;
  max-width: 380px;
`

const FlyoutMenu: React.FC<IFlyoutMenuProps> = ({ links, active, featuredType, showCategory, totalColumns, itemsPerColumn, highlightedLinks }) => {
	const [columns, setColumns] = useState([])
	const [featureds, setFeatureds] = useState<IFeatured[]>(null)
	const [currentFeatured, setCurrentFeatured] = useState<IFeatured>(null)
	const [featuredImageID, setFeaturedImageID] = useState<string>(null)
	const [highlightedColumnItems, setHighlightedColumnItems] = useState<ContentfulLink[]>([])

	const currentFeaturedSlug = currentFeatured?.slug || "/"
	const currentFeaturedTitle = currentFeatured?.title.title
	const currentFeaturedCategory = currentFeatured?.category
	const currentFeaturedDescription = currentFeatured?.description.description

	const handleItemMouseEnter = (itemFeatured: IFeatured) => {
		if (itemFeatured) {
			setFeaturedImageID(itemFeatured.referenceImage?.id || itemFeatured.heroImage?.id)
			setCurrentFeatured(itemFeatured)
		}
	}

	const setDefaultFeatured = () => {
		setCurrentFeatured(featureds[0])
		setFeaturedImageID(featureds[0]?.referenceImage?.id || featureds[0]?.heroImage?.id)
	}

	const finalFeaturedCategory = currentFeaturedCategory && currentFeaturedCategory === "brand bite" ? "brand bites series" : currentFeaturedCategory
	
	const generatedFeatureds = useMemo(() => links.map(link => link.featured), [links])
	const generatedColumns = useMemo(() => {
		const columnsSize = Math.ceil(links.length / totalColumns)
		const columnIndex = 0
		let currentColumn = []

		return links.reduce((columnsMenu, link, index) => {
			currentColumn.push(link)
			if (currentColumn.length ===  columnsSize || index === links.length - 1) {
				columnsMenu.push({ id: `column-${columnIndex}`, items: currentColumn })
				currentColumn = []
			}

			return columnsMenu
			  }, [])
	}, [links, itemsPerColumn, totalColumns])
			
	useEffect(() => setFeatureds(generatedFeatureds), [generatedFeatureds])
	useEffect(() => setColumns(generatedColumns), [generatedColumns])
	useEffect(() => {
		if (!currentFeatured && !featuredImageID && featureds) {
			setDefaultFeatured()
		}
		setHighlightedColumnItems(highlightedLinks || [])
	}, [featureds, highlightedLinks])

	return (
		<Container active={active}>
			{columns.map((column: IColumn) => {
				const { id, items } = column
				
				return (
					<>
						<Column key={id}>
							{items.map(item => {
								const { id: itemID, text, description: itemDescription, link: itemLink, featured: itemFeatured } = item
								return (
									<>
										<Item key={itemID} onMouseEnter={() => handleItemMouseEnter(itemFeatured)}>
											<Link css={linkStyles} to={getPath(itemLink)}>
												<ItemTitle><ArrowIcon/>{text}</ItemTitle>
												<ItemDescription>{itemDescription.description}</ItemDescription>
											</Link>
										</Item>
										
									</>
								)
							})}
						</Column>
						
						
					</>
				)
			})}

			{highlightedColumnItems.length > 0 && ( // Render the highlighted column if there are highlighted links
				<HighlightedColumn>
					{highlightedColumnItems.map(item => {
						const { id: itemID, text, description: itemDescription, link: itemLink, featured: itemFeatured } = item
						return (
							<Item key={itemID} onMouseEnter={() => handleItemMouseEnter(itemFeatured)}>
								<Link css={linkStyles} to={getPath(itemLink)}>
									<ItemTitle><ArrowIcon/>{text}</ItemTitle>
									<ItemDescription>{itemDescription.description}</ItemDescription>
								</Link>
							</Item>
						)
					})}
				</HighlightedColumn>
			)}
	  
			{featureds && featuredType === "page" && (
				<FeaturedPageContainer href={`/${currentFeaturedSlug}`}>
					<FeaturedPageImagesWrapper>
						{featureds
							.filter(featured => featured)
							.map(featured => {
								const image = featured.referenceImage || featured.heroImage
								const visible = image?.id === featuredImageID
								return (
									<FeaturedPageImageContainer key={image.id} visible={visible}>
										<Media {...image} />
									</FeaturedPageImageContainer>
								)
							})}
					</FeaturedPageImagesWrapper>
					{showCategory && finalFeaturedCategory && (
						<FeaturedPageCategory>{finalFeaturedCategory}</FeaturedPageCategory>
					)}
					<FeaturedTitle>{currentFeaturedTitle}</FeaturedTitle>
					<FeaturedDescription>{currentFeaturedDescription}</FeaturedDescription>
				</FeaturedPageContainer>
			)}
		</Container>
	)
}

export default React.memo(FlyoutMenu)
